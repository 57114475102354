body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.link{
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.isc{
  width: 100%;
;}

@media only screen and (max-width: 760px) {
  .isc{
    position: fixed;
    width: 95%;
    top:1%;
    right:2%;
    left:2%
  ;}  
}

.loglogin{
  background-image: url("./images/fv.png");
  height: 110px;
  width: 110px;
  background-size: cover;
  
}